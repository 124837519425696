import axios from "../_helpers/axios";

export const newsService = {
	get,
	add,
	edit,
	destroy,
	get_all,
};

async function get(data) {
	return new Promise((resolve, reject) => {
		axios
			.get("news/" + data)
			.then((resp) => {
				if (resp.data && resp.data.data) {
					resolve(resp.data.data);
				}
			})
			.catch(function(error) {
				if (
					error.response &&
					error.response.data &&
					error.response.data.error
				) {
					reject(error.response.data.error.error_message);
					return;
				}
				reject(error);
			});
	});
}

async function add(data) {
	return new Promise((resolve, reject) => {
		axios
			.post("news", data)
			.then((resp) => {
				resolve(resp);
			})
			.catch(function(error) {
				if (
					error.response &&
					error.response.data &&
					error.response.data.error
				) {
					reject(error.response.data.error);
					return;
				}
				reject(error);
			});
	});
}

async function edit(data) {
	return new Promise((resolve, reject) => {
		axios
			.put("news/" + data.id, data)
			.then((resp) => {
				resolve(resp);
			})
			.catch(function(error) {
				if (
					error.response &&
					error.response.data &&
					error.response.data.error
				) {
					reject(error.response.data.error);
					return;
				}
				reject(error);
			});
	});
}

async function destroy(data) {
	return new Promise((resolve, reject) => {
		axios
			.delete("news/" + data.id)
			.then((resp) => {
				resolve(resp);
			})
			.catch(function(error) {
				if (
					error.response &&
					error.response.data &&
					error.response.data.error
				) {
					reject(error.response.data.error.error_message);
					return;
				}
				reject(error);
			});
	});
}

async function get_all(account, last_date = null) {
	return new Promise((resolve, reject) => {
		axios
			.get(`news?account_id=${account}&last_date=${last_date}`)
			.then((resp) => {
				if (resp.data && resp.data.data) {
					resolve(resp.data.data);
				}
			})
			.catch(function(error) {
				if (
					error.response &&
					error.response.data &&
					error.response.data.error
				) {
					reject(error.response.data.error.error_message);
					return;
				}
				reject(error);
			});
	});
}
